import axios from '@/api/axios';
import { transformLog } from './utils';

export function getComputers() {
  return axios.get('/computers')
    .then(({ data: { tree, computers, groups } }) => ({ tree, computers, groups }));
}

export function getComputersAndVncKey() {
  return axios.get('/computers/vnc').then((response) => {
    if (response.data) {
      return response.data;
    }
    return 'error';
  });
}

export function getLogsCount(compId, dateFrom, dateTo) {
  return axios.get(`/computers/logsCount?compId=${compId}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
    .then(({ data }) => data);
}

export function getAllComputers() {
  return axios.get('/computers/all')
    .then(({ data: { tree, computers } }) => ({ tree, computers }));
}

export function getComputer(id) {
  return axios.get(`/computers/${id}`)
    .then(({ data }) => data);
}

export function deleteComputerLogs(id) {
  return axios.delete(`/computers/${id}/logs`);
}

/**
 * Удаление категории логов компьютера
 * @param {Number} id - идентификатор компьютера
 * @param {String} category - символьный код категории
 */
export function deleteLogsCategory(id, category) {
  return axios.delete(`/computers/${id}/logs/${category}`);
}

/**
 * Удаление элемента лога
 * @param {Number} idComputer - идентификатор компьютера
 * @param {String} category - символьный код категории
 * @param {Number} id - идентификатор записи
 */
export function deleteLogsItem(idComputer, category, id) {
  return axios.delete(`/computers/${idComputer}/logs/${category}/${id}`);
}

export function deleteLogTypeForAllComputers(category) {
  return axios.delete(`/computers/${category}`);
}

/**
 * Получение логов
 * @param {number} computerId - идентификатор компьютера
 * @param {string} log - символьный код вида лога
 * @param {number} lastId - последний видимый пользователю идентификатор, влияет на статус "NEW"
 * @param {*} dateFrom - дата "от"
 * @param {*} dateTo - дата "до"
 */
export function getLogs(computerId, log, lastId = 0, dateFrom, dateTo) {
  return axios.get(`/computers/${computerId}/logs/${log}?last-id=${lastId}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
    .then(({ data }) => transformLog(log, data));
}

export function getPagedLogs(computerId, log, lastId = 0, dateFrom, dateTo, index, size) {
  return axios.get(
    // eslint-disable-next-line
    `/computers/${computerId}/logs/${log}?last-id=${lastId}&dateFrom=${dateFrom}&dateTo=${dateTo}&index=${index}&size=${size}`,
  )
    .then((response) => {
      if (response.data.success) {
        return transformLog(log, response.data.result);
      }
      return 'error';
    });
}

export function getMultiuserLogs(computerIds, log, dateFrom, dateTo) {
  return axios.post(
    // eslint-disable-next-line
    `/computers/logs/${log}?dateFrom=${dateFrom}&dateTo=${dateTo}`, { computerIds }
  )
    .then((response) => {
      if (response.data.success) {
        return transformLog(log, response.data.result);
      }
      return 'error';
    });
}

function forceFileDownload(response) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', response.headers.filename);
  document.body.appendChild(link);
  link.click();
}
function getFile(link) {
  return axios.get(link,
    { responseType: 'blob' }).then((response) => {
    forceFileDownload(response);
    return { success: true };
  }).catch((error) => {
    if (error.response) {
      if (error.response.status === 404) return { success: false, message: 'Файл не найден' };
    }
    return { success: false, message: 'Неизвестная ошибка' };
  });
}

export function getScreenshotLink(id, filename) {
  return `${axios.defaults.baseURL}/computers/${id}/screenshots/${filename}`;
}

export function getPrinterDocumentLink(computerId, recordId) {
  const link = `computers/${computerId}/printers/${recordId}`;
  return getFile(link);
}

export function getFileUploadAttachmentLink(computerId, recordId, attachmentId) {
  return `${axios.defaults.baseURL}/computers/${computerId}/file-upload/${recordId}/${attachmentId}`;
}

export function getFileUploadAttachment(computerId, recordId, attachmentId) {
  const link = `/computers/${computerId}/file-upload/${recordId}/${attachmentId}`;
  return getFile(link);
}

export function getWebMailAttachmentLink(computerId, recordId, attachmentId) {
  return `${axios.defaults.baseURL}/computers/${computerId}/web-mail/${recordId}/${attachmentId}`;
}

export function getMessengersAttachment(computerId, recordId) {
  const link = `/computers/${computerId}/logs/messengers/${recordId}/file`;
  return getFile(link);
}

export function getMessengersAttachmentLink(computerId, recordId) {
  return `${axios.defaults.baseURL}/computers/${computerId}/logs/messengers/${recordId}/attachment`;
}

export function getResultNumber(computerId, log, dateFrom, dateTo) {
  return axios.get(`/computers/${computerId}/logs/${log}/count?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    .then(({ data }) => data);
}

export function getEmailClientsMailLink(computerId, recordId) {
  return `${axios.defaults.baseURL}/computers/${computerId}/logs/email-clients/${recordId}/mail`;
  // return axios.get(`/computers/${computerId}/logs/email-clients/${recordId}/mail`);
}

export function getShadowCopyingFile(computerId, recordId) {
  const link = `computers/${computerId}/logs/shadow-copyings/${recordId}/file`;
  return getFile(link);
}

export function getWebcamsAndMicrophonesFile(computerId, recordId) {
  const link = `/computers/${computerId}/logs/webcams-and-microphones/${recordId}/file`;
  return getFile(link);
}

export function getMicRecordFile(computerId, recordId) {
  const link = `/computers/${computerId}/logs/mic-records/${recordId}/file`;
  return getFile(link);
}

export function getClipboardFile(computerId, recordId) {
  const link = `/computers/${computerId}/logs/clipboard/${recordId}/file`;
  return getFile(link);
}

export function getClipboardFileLink(computerId, recordId) {
  return `${axios.defaults.baseURL}/computers/${computerId}/logs/clipboard/${recordId}/screenshot`;
}
